import { render, staticRenderFns } from "./RegistryForm.vue?vue&type=template&id=1c87266d&scoped=true&"
import script from "./RegistryForm.vue?vue&type=script&lang=js&"
export * from "./RegistryForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c87266d",
  null
  
)

export default component.exports